import { FooterSection } from '@/components/Marketing/FooterSection'
import { HeadMeta } from '@/components/Marketing/HeadMeta'
import { AISection } from '@/components/Marketing/Home/AISection'
import { CTASection } from '@/components/Marketing/Home/CTASection'
import { FeaturesSection } from '@/components/Marketing/Home/FeaturesSection'
import { HeroSection } from '@/components/Marketing/Home/HeroSection'
import { Navbar } from '@/components/Marketing/Navbar'
import { ShowcaseSection } from '@/components/Marketing/ShowcaseSection'

import { getHostUrl } from '@/utils/getHostUrl'
import { NextPage } from 'next'

const IndexPage: NextPage = () => {
  return (
    <>
      <Head />
      <Navbar />
      <HeroSection />
      <AISection />
      <ShowcaseSection />
      <FeaturesSection />
      <CTASection />
      <FooterSection />
    </>
  )
}

const Head = () => {
  return (
    <HeadMeta
      canonical={getHostUrl() + '/'}
      title="Pathway - Home"
      description="Pathway is an accurate and efficient point-of-care reference tool that gives you rapid access to evidence-based guideline summaries, interactive algorithms"
    />
  )
}

export default IndexPage
