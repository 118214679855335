import Image from 'next/image'
import Link from 'next/link'

import { LogoCloudSection } from '../LogoCloud/LogoCloud'

export const HeroSection = () => {
  return (
    <>
      {/* Hero section */}
      <div className="relative isolate bg-white pt-6">
        <div className="sm:pt-6">
          {/* Hero header */}
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-3xl text-center">
              {/* Hero header */}
              <h1 className="mt-8 text-4xl font-semibold tracking-tighter text-gray-900 sm:text-5xl md:text-6xl lg:text-7xl lg:leading-[1.0625]">
                <p>Rapid clinical guidance</p>
              </h1>

              {/* Hero description */}
              <p className="text-md mt-6 text-gray-500 sm:text-2xl sm:leading-9">
                Pathway provides access to concise summaries of clinical
                guidelines, trials and more, saving you hours every week.
              </p>

              {/* Hero subheader */}
              <div className="mt-8 flex items-center justify-center gap-x-4">
                <Link
                  href="/home"
                  className="rounded-lg bg-blue-600 bg-transparent bg-gradient-to-r from-blue-500 to-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:px-5 sm:py-2.5 sm:text-lg"
                >
                  Start for Free
                </Link>
                <Link
                  href="https://pathwaymd.onelink.me/LROn/439f4b9c"
                  className="box-border block rounded-lg border border-gray-200 px-4 py-2 font-medium text-gray-900 sm:hidden sm:px-5 sm:py-2.5 sm:text-lg"
                >
                  Download app
                </Link>
              </div>
            </div>

            {/* Hero image */}
            <div className="mx-auto mt-16 flow-root max-w-5xl sm:mt-24">
              <Image
                src="/marketing/hero-screenshot.jpg"
                alt="App screenshot"
                width={1024}
                height={768}
              />
            </div>
            {/* Logo Cloud*/}
            <div className="py-16 lg:py-32">
              <LogoCloudSection />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
