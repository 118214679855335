import Image from 'next/image'

import clsx from 'clsx'

const showcases = [
  {
    name: 'Guideline summaries',
    title: 'Read guidelines in minutes',
    description:
      'Quickly find and read hundreds of efficient and accurate guideline summaries.',
    image: '/marketing/feature-guideline-summaries.jpg',
    imageAlt: 'Guidelines',
    quote: {
      text: 'The simple layout of guidelines allows for rapid access of clinical guidelines which is incredibly handy in a time sensitive situation.',
      name: 'George Farkas',
      title: 'Clinical Pharmacist, Glasgow, UK',
    },
  },
  {
    name: 'Evidence review',
    title: 'Easily assess the evidence',
    description:
      'Effortlessly interpret evidence with color codes and clear explanations for 300+ grading schemes. Bolster your treatment choices using always-available original references.',
    image: '/marketing/feature-evidence-reviews.jpg',
    imageAlt: 'Evidence',
  },
  {
    name: 'Landmark trials',
    title: 'Breeze through the latest trials',
    description:
      'Delve into landmark trials with succinct summaries and direct links to the original text. Keep your knowledge up-to-date and refresh your memory in an instant.',
    image: '/marketing/feature-landmark-trials.jpg',
    imageAlt: 'landmark trials',
  },
  {
    name: 'Interactive pathways',
    title: 'Get patient-specific guidance',
    description:
      'Use mobile-friendly interactive algorithms to reduce error and take the complexity out of aligning with best practices.',
    image: '/marketing/feature-interactive-pathways.jpg',
    imageAlt: 'Interactive Pathways',
  },
]

export const ShowcaseSection = () => {
  return (
    <>
      {showcases.map((showcase, index) => (
        <div
          key={index}
          className="overflow-hidden border-t border-gray-200 bg-white py-12 sm:py-32"
        >
          <div className="mx-auto max-w-5xl px-6">
            <div className="grid grid-cols-1 items-center gap-x-8 gap-y-8 lg:grid-cols-3">
              <div
                className={clsx(
                  index % 2 != 0 && 'lg:pl-20',
                  'order-1 col-span-2 lg:pr-4 lg:pt-4',
                )}
              >
                <div className="mx-auto sm:pb-8 lg:mx-0">
                  <h2 className="font-medium leading-7 text-blue-600">
                    {showcase.name}
                  </h2>
                  <p className="mt-3 text-2xl font-semibold tracking-tight text-gray-900 sm:mt-3 sm:text-4xl">
                    {showcase.title}
                  </p>
                  <p className="mt-4 text-gray-600 sm:mt-6 sm:text-xl sm:leading-8 lg:max-w-xl">
                    {showcase.description}
                  </p>
                  {showcase.quote && (
                    <figure className="mt-8 border-l border-gray-200 pl-8 text-gray-600 lg:max-w-xl">
                      <blockquote className="leading-7 text-gray-500">
                        <p>{showcase.quote.text}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex gap-x-4 text-sm leading-6 text-gray-500">
                        <div>
                          <span className="font-semibold text-gray-900">
                            {showcase.quote.name}
                          </span>{' '}
                          – {showcase.quote.title}
                        </div>
                      </figcaption>
                    </figure>
                  )}
                </div>
              </div>
              <div
                className={clsx(
                  index % 2 != 0 && 'lg:order-none',
                  'order-1 max-w-[16rem] md:max-w-md lg:max-w-lg',
                )}
              >
                <Image
                  src={showcase.image}
                  alt={showcase.imageAlt}
                  width={620}
                  height={300}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
