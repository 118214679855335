import Link from 'next/link'

export const CTASection = () => {
  return (
    <div className="border-t border-gray-200 bg-white py-12 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto text-center">
          <h2 className="text-sm font-medium leading-7 text-blue-600 sm:text-base">
            Try Pathway today
          </h2>
          <p className="mx-auto mt-2 text-2xl font-semibold tracking-tight text-gray-900 sm:mt-3 sm:text-4xl sm:font-semibold sm:leading-[1.15]">
            Evidence-based medicine, simplified
          </p>
          <p className="mx-auto mt-4 max-w-3xl leading-7 text-gray-600 sm:mt-6 sm:text-xl sm:leading-8">
            Instantly access hundreds of point-of-care guideline summaries,
            interactive algorithms, key findings from landmark trials, clinical
            calculators, and more. Always available offline, wherever you go.
          </p>
          {/* Hero subheader */}
          <div className="mt-6 flex justify-center gap-x-4 sm:mt-8">
            <Link
              href="/auth/sign-in"
              className="rounded-lg bg-blue-600 bg-transparent bg-gradient-to-r from-blue-500 to-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:px-5 sm:py-2.5 sm:text-lg"
            >
              Start for Free
            </Link>
            <Link
              href="https://pathwaymd.onelink.me/LROn/439f4b9c"
              className="box-border block rounded-lg border border-gray-200 px-4 py-2 font-medium text-gray-900 sm:hidden sm:px-5 sm:py-2.5 sm:text-lg"
            >
              Download app
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
