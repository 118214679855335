import {
  BookOpen,
  ChartPie,
  Checks,
  FirstAid,
  FlowArrow,
  MagnifyingGlass,
  Medal,
  Pill,
  Signpost,
} from '@phosphor-icons/react'

const features = [
  {
    name: 'Guideline summaries.',
    description:
      'Quickly find and read guideline summaries, optimized for quick consultation at the bedside.',
    icon: BookOpen,
  },
  {
    name: 'Landmark trials.',
    description:
      'Refer to landmark trials for a specific disease, with summaries of key findings and links to the original text.',
    icon: ChartPie,
  },
  {
    name: 'Interactive pathways.',
    description:
      'Use interactive algorithms to take the guesswork out of adopting best practices.',
    icon: FlowArrow,
  },
  {
    name: 'Calculators.',
    description:
      'Access a large library of diagnostic and risk prediction calculators, integrated within guidelines.',
    icon: Checks,
  },
  {
    name: 'Differential diagnoses.',
    description:
      'Easily consult pertinent findings and differentials when a diagnosis is under question.',
    icon: Signpost,
  },
  {
    name: 'Efficient search.',
    description:
      'Use common and uncommon abbreviations, synonyms and alternative spellings.',
    icon: MagnifyingGlass,
  },
  {
    name: 'Clinical findings. ',
    description:
      'Access succinct summaries with emphasized likelihood ratios for quick, data-driven decisions.',
    icon: FirstAid,
  },
  {
    name: 'Drugs (preview).',
    description:
      'Get concise drug info detailing key information for precise, informed prescription practices.',
    icon: Pill,
  },

  {
    name: 'CME.',
    description:
      'Earn CME credits for every search and every article you read.',
    icon: Medal,
  },
]

export const FeaturesSection = () => {
  return (
    <div className="border-t border-gray-200 bg-white py-12 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto">
          <h2 className="font-medium leading-7 text-blue-600">
            For clinicians, by clinicians
          </h2>
          <p className="mt-3 text-2xl font-semibold tracking-tight text-gray-900 sm:mt-3.5 sm:text-4xl">
            Your instant second opinion
          </p>
          <p className="mt-3 leading-7 text-gray-600 sm:mt-6 sm:text-xl sm:leading-8">
            Our physician authors and editors painstakingly review the highest
            quality evidence-based clinical information to give you condensed,
            accurate and reliable summaries.
          </p>
        </div>
        <div className="mx-auto mt-12 sm:mt-16">
          <dl className="mx-auto grid grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon
                    className="absolute left-1 top-1 h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>{' '}
                <dd className="inline text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
