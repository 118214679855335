import Link from 'next/link'

const questions = [
  {
    id: 1,
    question: 'What are the criteria to screen for ovarian cancer?',
    href: 'https://www.pathway.md/ai/history/a0631e69-af08-45dc-a914-636e2a209dfd',
  },
  {
    id: 2,
    question: 'What causes basophilic stippling?',
    href: 'https://www.pathway.md/ai/history/780afbe5-4abe-4bcc-a4ee-6b89f2e6730a',
  },
  {
    id: 3,
    question: 'Should I use statins for CHF?',
    href: 'https://www.pathway.md/ai/history/86110171-8e70-4bef-99bc-26242e31b9e4',
  },
]

export const AISection = () => {
  return (
    <div className="border-t border-gray-200 bg-white py-12 sm:py-32">
      <div className="mx-auto max-w-5xl px-6 lg:px-8">
        <div className="mx-auto">
          <p className="mt-3 text-2xl font-semibold tracking-tight text-gray-900 sm:mt-3.5 sm:text-4xl">
            Try Pathway AI
          </p>
          <p className="mt-3 leading-7 text-gray-600 sm:mt-6 sm:text-xl sm:leading-8">
            Introducing our AI assistant — bringing you fast, reliable clinical
            answers backed by high-quality, verifiable references.
          </p>
          <div className="items-left justify-left mt-8 flex gap-x-4">
            <Link
              href="/ai"
              className="sm:text-md rounded-lg bg-blue-600 bg-transparent bg-gradient-to-r from-blue-500 to-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:px-4 sm:py-2"
            >
              Ask a question
            </Link>
            <Link
              href="/ai"
              className="sm:text-md box-border block rounded-lg border border-gray-200 px-4 py-2 font-medium text-gray-900 sm:px-4 sm:py-2"
            >
              Sign up
            </Link>
          </div>
          <div className="mt-8 flex flex-col gap-y-3">
            {questions.map((question) => (
              <Link
                href={question.href}
                key={question.id}
                className="hover:opacity-85"
                target="_blank"
              >
                <div
                  key={question.id}
                  className="rounded-xl border border-gray-200 px-5 py-2.5 shadow-sm hover:border-gray-300"
                >
                  <div className="flex items-center justify-start gap-x-3">
                    <p className="border-r border-gray-200 pr-3 text-blue-600">
                      Q
                    </p>
                    <p className="text-md text-gray-500">{question.question}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
